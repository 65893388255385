@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

/* CSS Reset */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	font-size: 14px;
	line-height: 1.6;
}

body {
	font-family: Inter, sans-serif;
	background-color: #212121;
	color: #ececec;
}

ul,
ol {
	list-style: none;
}

a {
	text-decoration: none;
	color: inherit;
}

img,
video {
	max-width: 100%;
	height: auto;
	display: block;
}

button,
input,
textarea {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	outline: none;
	box-shadow: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

blockquote,
q {
	quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
	content: "";
	content: none;
}

.ui {
	&Container {
		height: 100dvh;
		display: grid;
		grid-template-columns: 350px 1fr 300px;
		gap: 20px;
	}

	&Chats {
		height: 100dvh;
		background-color: #0d0d0d;
		overflow-y: scroll;

		.newChat {
			width: 100%;
			padding: 20px 20px;

			button {
				width: 100%;
				height: 40px;
				background-color: #212121;
				border-radius: 8px;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 18px;
				cursor: pointer;
				transition: 0.2s linear;
				border: 1px solid #4a4a4a;

				&:hover {
					transform: scale(1.03);
					color: #fff;
					border: 1px solid #777777;
				}
			}
		}

		.list {
			width: 100%;
			list-style: none;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 10px;
			padding: 30px 20px;
		}

		.item {
			width: 100%;
			height: 40px;
			padding: 10px 20px;
			border-radius: 8px;
			cursor: pointer;
			transition: 0.2s linear;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			&:hover {
				background-color: #212121;
			}
		}

		._active {
			background-color: #212121;
		}
	}

	&CurrentChat {
		display: flex;
		justify-content: center;

		.container {
			max-width: 800px;
			max-height: 100dvh;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-top: 80px;
		}

		.messagesList {
			max-height: calc(100dvh - 200px);
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 20px;
			text-align: start;
			overflow-y: auto;
			padding: 0px 20px 0px 0px;

			&::-webkit-scrollbar {
				// display: none;
			}
		}

		.messagesItem {
			width: 100%;
			padding: 20px;
			background-color: #0d0d0d;
			border-radius: 8px;
			white-space: pre-wrap;
      display: flex;
      flex-direction: column;
      gap: 15px;

			.id {
				background-color: #424242;
				width: fit-content;
				padding: 10px 20px;
				border-radius: 8px;
			}

      .image {
        max-width: 60%;
        border-radius: 8px;
      }

			.plug {
				min-width: 100%;
				height: 40px;
				display: flex;
				justify-content: center;
			}

			.loader,
			.loader:before,
			.loader:after {
				border-radius: 50%;
				width: 2.5em;
				height: 2.5em;
				animation-fill-mode: both;
				animation: bblFadInOut 1.8s infinite ease-in-out;
			}
			.loader {
				color: #fff;
				font-size: 7px;
				position: relative;
				text-indent: -9999em;
				transform: translateZ(0);
				animation-delay: -0.16s;
			}
			.loader:before,
			.loader:after {
				content: "";
				position: absolute;
				top: 0;
			}
			.loader:before {
				left: -3.5em;
				animation-delay: -0.32s;
			}
			.loader:after {
				left: 3.5em;
			}

			@keyframes bblFadInOut {
				0%,
				80%,
				100% {
					box-shadow: 0 2.5em 0 -1.3em;
				}
				40% {
					box-shadow: 0 2.5em 0 0;
				}
			}
		}

		.inputContainer {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: end;
			padding: 50px 0px;
			gap: 10px;

			input {
				width: 100%;
				padding: 20px 20px;
				background-color: #424242;
				resize: none;
				field-sizing: content;
				min-height: 1lh;
				border-radius: 8px;
				font-size: 18px;
				min-height: 62px;
			}

			button {
				background-color: #424242;
				font-size: 18px;
				border-radius: 8px;
				padding: 20px 20px;
				max-height: 62px;
				cursor: pointer;
				transition: 0.2s linear;
				border: 1px solid #4a4a4a;

				&:hover {
					color: #fff;
					border: 1px solid #777777;
				}
			}
		}
	}

	&Settings {
		height: 100dvh;
		background-color: #0d0d0d;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
    gap: 20px;
		padding: 20px;

		.field {
			display: flex;
			flex-direction: column;

			input[type="text"] {
				width: 100%;
				padding: 20px 20px;
				background-color: #424242;
				resize: none;
				field-sizing: content;
				min-height: 1lh;
				border-radius: 8px;
				font-size: 18px;
				min-height: 62px;
			}
		}

    .field2 {
      display: flex;
      align-items: center;
      gap: 10px;

      input[type='checkbox'] {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
	}
}
